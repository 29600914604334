<template>
  <div>
    <span v-if="loading">{{loading_text}}</span>
    <multi-select v-else
      :modelValue="selected_options"
      @select="handleSelectScript"
      @remove="handleRemoveScript"
      :placeholder="placeholder"
      label="label"
      track-by="key"
      :options="options.filter(option => hide_modules.indexOf(option.key) < 0)"
      :multiple="multiple"
      :disabled="disabled"
      :selectLabel="selectLabel"
      :deselectLabel="deselectLabel"
      :allowEmpty="allowEmpty"
    ></multi-select>
  </div>
</template>

<script>
import api_scripts from './../../api/scripts.js'
import { get_sso_url } from './../../utils/sso.js'

export default {
  name: 'modules-field',
  props: {
    modelValue: {
      required: true
    },
    loading_text: {
      type: String,
      default: 'Loading modules..'
    },
    placeholder: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
    },
    versions_of: {
      type: String
    },
    selectLabel: {
      type: String
    },
    deselectLabel: {
      type: String
    },
    allowEmpty: {

    },
    reverse: {
      type: Boolean,
      default: false
    },
    hide_modules: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      loading: true,
      loading_failed: false,
      options: []
    }
  },
  computed: {
    value_as_array: function () {
      return Array.isArray(this.modelValue)
        ?this.modelValue
        :this.modelValue !== null && this.modelValue !== undefined
          ?[this.modelValue]
          :[]
    },
    selected_options: function () {
      const selected = this.options.filter(option => this.value_as_array.indexOf(option.key) >= 0)
      return this.multiple
        ?selected
        :selected.length?selected[0]:null
    }
  },
  methods: {
    loadData() {
      // Init user cache (need for showing module tags) and load modules
      this.loading = true
      this.loading_failed = false
      return this.$userCache.initialize()
        .then(() => api_scripts.get_list({}))
        .then((response) => {
          this.loading = false
          if (response.status == 200) {
            this.loading_failed = false
            const options = response.data
              .filter(script => {
                if (this.versions_of) {
                  return this.$userCache.$versions.get_versions_of(this.versions_of).indexOf(script.id) >= 0
                }
                return true
              })
              .map(script => {
                const script_tag = this.$userCache.$tags.get_tag(script.id)
                return {
                  key: script.id,
                  label: `${script_tag?script_tag:script.id.substring(0,8)} (${script.name})`
                }
              })
            this.options = this.reverse?options.slice().reverse():options
            return Promise.resolve()
          } else {
            return Promise.reject({response})
          }
        })
        .catch(error => {
          this.loading = false
          this.loading_failed = true
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            // alert user
            alert('Could not load modules list.')
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    handleSelectScript({key}) { // Note: vue-multiselect sends the whole option object on selection, hence the "{key}" as parameter here
      if (this.multiple) {
        let value = this.value_as_array
        // append the newly selected option if not already selected
        if (value.indexOf(key) < 0) {
          value.push(key)
        }
        this.$emit('update:modelValue', value)
      } else {
        this.$emit('update:modelValue', key)
      }
    },
    handleRemoveScript({key}) { // Note: vue-multiselect sends the whole option object on removal, hence the "{key}" as parameter here
      this.$emit(
        'update:modelValue',
        this.multiple
          ?this.value_as_array.filter(x => x !== key)
          :null
      )
    }
  },
  mounted: function() {
    this.loadData()
  }
}
</script>
