import queryString from 'query-string'

import api_base from './base.js'


const api_scripts = {
  get_single: (id, include_content = false) => (
    api_base(true).get(`/user/v1/scripts/${id}/${include_content?'?include_content':''}`)
  ),
  get_list: ({include_content = undefined}) => {
    const params = queryString.stringify({include_content})
    return api_base(true).get(`/user/v1/scripts/${params?'?':''}${params}`)
  },
  delete: (id) => (
    api_base(true).delete(`/user/v1/scripts/${id}/`)
  ),
  create: ({name, content}) => (
    api_base(true).post('/user/v1/scripts/', {
      name,
      content: btoa(content)
    })
  )
}

export default api_scripts
