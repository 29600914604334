import api_base from './base.js'


const api_utils = {
  remote_fetch: (url) => (
    api_base(true).get(`/user/v1/utils/remote-fetch/?url=${url}`)
  )
}

export default api_utils
