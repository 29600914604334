import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import Buefy from '@ntohq/buefy-next'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import VueClipboard from 'vue-clipboard2'
import VueSimpleAlert from '../vendor/vue3-simple-alert/lib'
import Multiselect from 'vue-multiselect'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTimes, faCopy, faPlus, faShoppingCart, faEdit, faPen, faHistory, faCaretUp, faCaretDown,
  faEllipsisV, faDownload, faFileCode, faMicrochip, faLink
} from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faPython } from '@fortawesome/free-brands-svg-icons'

import config from './../app.config.json'
import App from './components/App.vue'
import routes from './routes.js'
import UserCache from './plugins/cache.js'

import 'vue-multiselect/dist/vue-multiselect.css'
import './styles/main.scss'

const app = createApp(App)

if (config.SENTRY_API_URL) {
  Raven
    .config(`${config.SENTRY_API_URL}`, {
      environment: process.env.NODE_ENV
    })
    .addPlugin(RavenVue, app)
    .install()
}

// import any used font awesome 5 icons here.
library.add(
  faTimes, faCopy, faShoppingCart, faPlus, faEdit, faPen, faHistory, faPython,
  faTrashAlt, faCaretUp, faCaretDown, faEllipsisV, faDownload, faFileCode, faMicrochip, faLink
)

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('multi-select', Multiselect)

app.use(Buefy, {
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas',
})
app.use(VueClipboard)
app.use(VueSimpleAlert)
app.use(UserCache)

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ top: 0 }),
  routes
})
app.use(router)

app.mount('#app')
