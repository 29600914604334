<template>
  <div class="container is-fluid" :style="{'padding-top': '40px'}">

    <h3 class="is-size-4" :style="{marginBottom: '2rem'}">Claim a device</h3>

    <claim-device-form
      ref="claimDeviceForm"
      @close="$router.push({name: 'devices_list'})"
      @claimed="$router.push({name: 'devices_list'})"
    />

  </div>
</template>

<script>
/* istanbul ignore file */

import ClaimDeviceForm from './../forms/ClaimDeviceForm.vue'

export default {
  name: 'ClaimDeviceScreen',
  components: {
    'claim-device-form': ClaimDeviceForm
  },
}
</script>
