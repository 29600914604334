<template>
  <div>

    <div :style="{marginBottom: '2rem'}">

      <div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label has-text-left">Module</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <modules-field
                  placeholder="Select module"
                  :multiple="false"
                  :disabled="Boolean(context_script_id)"
                  :hide_modules="hide_modules"
                  v-model="v$.value.module.$model"
                />
                <div v-if="v$.value.module.$error">
                  <p class="help is-danger" v-if="!v$.value.module.required">Field is required.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label has-text-left">Target device</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <devices-field
                  placeholder="Select device"
                  :multiple="false"
                  :show_all_option="false"
                  :disabled="Boolean(context_device_id)"
                  :hide_devices="hide_devices"
                  v-model="v$.value.device.$model"
                />
                <div v-if="v$.value.device.$error">
                  <p class="help is-danger" v-if="!v$.value.device.required">Field is required.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <button
            class="back-button button is-light"
            :disabled="submitting"
            @click.prevent="$emit('close')"
          >Cancel</button>
        </div>
      </div>

      <!-- Right side -->
      <div class="level-right">
        <div class="level-item">
          <button
            :class="['forward-button button is-pozetron-primary has-tooltip-left', {'is-loading': submitting}]"
            :disabled="v$['value'].$invalid"
            :data-tooltip="v$['value'].$invalid?'Please ensure all fields are valid':null"
            @click.prevent="handleSubmit"
          >
            Deploy
          </button>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import api_devices from './../../../api/devices'
import { get_sso_url } from './../../../utils/sso'

import DevicesField from './../../common/DevicesField.vue'
import ModulesField from './../../common/ModulesField.vue'


export default {
  name: 'deploy-module-form',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    DevicesField,
    ModulesField
  },
  props: {
    context_device_id: {
      type: String
    },
    context_script_id: {
      type: String
    },
    hide_devices: {
      type: Array,
      default: () => ([])
    },
    hide_modules: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['close', 'submitted'],
  data () {
    return {
      value: {
        device: this.context_device_id?this.context_device_id:'',
        module: this.context_script_id?this.context_script_id:''
      },
      submitting: false,
    }
  },
  validations: {
    value: {
      device: {
        required
      },
      module: {
        required
      }
    }
  },
  methods: {
    handleSubmit() {
      this.submitting = true

      return api_devices.deploy_module(this.value.device, this.value.module)
        .then(response => {
          if (response.status == 204) {
            this.submitting = false
            this.$emit('submitted', response.data);
            return Promise.resolve()
          } else {
            return Promise.reject(response)
          }
        })
        .catch(error => {
          this.submitting = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    }
  }
}
</script>
