import axios from 'axios'
import Cookies from 'js-cookie'
import config from './../../app.config.json'


const get_jwt = () => {
  const jwt_cookie = Cookies.get(config.JWT_COOKIE_NAME)
  return jwt_cookie !== undefined
    ?jwt_cookie
    :config.DEV_FALLBACK_JWT
}

const api_base = (authorize = false) => {
  return axios.create({
    baseURL: config.SERVER_URL,
    ...authorize
      ?{
        headers: {
          'authorization': `JWT ${get_jwt()}`
        }
      }
      :{}
  })
}

export default api_base
