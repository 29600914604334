import api_base from './base.js'

const api_cache = {
  get: () => (
    api_base(true).get('/user/v1/cache/')
  ),
  update_cache: (data, timestamp = Date.now()) => (
    api_base(true).put('/user/v1/cache/', {
      data,
      timestamp
    })
  )
}

export default api_cache
