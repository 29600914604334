<template>
  <div>
    <loading-indicator v-if="loading" text="Loading modules.." />

    <div v-else>

      <div class="buttons">
        <router-link
          :to="`/modules/new?deploy_to=${device_id}`"
          class="button is-light is-small"
          :style="{verticalAlign: 'baseline'}"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'plus']" />
          </span>
          <span>Deploy a <strong>new</strong> module</span>
        </router-link>
        <a
          href="#"
          @click.prevent="is_deploy_module_modal_active = true"
          class="button is-light is-small"
          :style="{verticalAlign: 'baseline'}"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'plus']" />
          </span>
          <span>Deploy an <strong>existing</strong> module</span>
        </a>
      </div>

      <nav v-if="deployed_modules.length > 0" class="panel">
        <modules-list-item
          v-for="deployed_module in deployed_modules"
          :key="deployed_module.id"
          :data="deployed_module"
          :context_device_id="device_id"
          @deleted="deployed_modules = deployed_modules.filter(x => deployed_module.id !== x.id)"
        />
      </nav>
      <div v-else>
        You did not deploy any modules to this device, yet.
      </div>

      <bulma-modal
        v-if="is_deploy_module_modal_active"
        :is_active="true"
        @close="is_deploy_module_modal_active = false"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">Deploy module</p>
          <button
            class="button is-white"
            @click.prevent="is_deploy_module_modal_active = false"
          >
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'times']" />
            </span>
          </button>
        </header>

        <section class="modal-card-body" :style="{'overflow': 'initial'}">

          <deploy-module-form
            :context_device_id="device_id"
            :hide_modules="deployed_modules.map(deployed_module => deployed_module.id)"
            @close="is_deploy_module_modal_active = false"
            @submitted="is_deploy_module_modal_active = false; loadData()"
          />

        </section>
      </bulma-modal>

    </div>
  </div>
</template>

<script>
import api_devices from './../../api/devices.js'
import LoadingIndicator from './../common/LoadingIndicator.vue'
import BulmaModal from './../common/BulmaModal.vue'
import { get_sso_url } from './../../utils/sso.js'

import ModulesListItem from './modules/ModulesListItem'
import DeployModuleForm from './modules/DeployModuleForm'


export default {
  name: 'devices-single-screen-modules',
  components: {
    LoadingIndicator,
    BulmaModal,
    ModulesListItem,
    DeployModuleForm
  },
  props: {
    device_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      deployed_modules: [],
      is_deploy_module_modal_active: false,
    }
  },
  methods: {
    loadData() {
      // reset deployed_modules
      this.deployed_modules = []

      // fetch deployed_modules
      return api_devices.get_deployed_modules(this.device_id)
        .then((response) => {
          this.loading = false
          if (response.status == 200) {
            this.deployed_modules = response.data.slice().reverse()
            return Promise.resolve(response)
          }
          return Promise.reject({response})
        })
        .catch((error) => {
          this.loading_logs = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    }
  },
  mounted: function() {
    this.loadData()
  }
}
</script>
