<template>
  <div>
    <span v-if="loading">Loading devices..</span>
    <multi-select v-else
      :modelValue="selected_options"
      @select="handleSelectDevice"
      @remove="handleRemoveDevice"
      :placeholder="placeholder"
      label="label"
      track-by="key"
      :options="full_options.filter(option => hide_devices.indexOf(option.key) < 0)"
      :multiple="multiple"
      :disabled="disabled"
    ></multi-select>
  </div>
</template>

<script>
import api_devices from './../../api/devices.js'
import { get_sso_url } from './../../utils/sso.js'

import {
  ALL_DEVICES_KEY
} from './../../constants.js'

export default {
  name: 'devices-field',
  props: {
    modelValue: {
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
    },
    show_all_option: {
      type: Boolean,
      required: true
    },
    hide_devices: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      loading: true,
      loading_failed: false,
      options: []
    }
  },
  computed: {
    full_options: function () {
      return [
        ...this.show_all_option?[{ label: 'All devices', key: ALL_DEVICES_KEY }]:[],
        ...this.options
      ]
    },
    value_as_array: function () {
      return Array.isArray(this.modelValue)
        ?this.modelValue
        :this.modelValue !== null && this.modelValue !== undefined
          ?[this.modelValue]
          :[]
    },
    selected_options: function () {
      const selected = this.full_options.filter(option => this.value_as_array.indexOf(option.key) >= 0)
      return this.multiple
        ?selected
        :selected.length?selected[0]:null
    }
  },
  methods: {
    loadData() {
      // Init user cache (need for showing device tags) and load devices
      this.loading = true
      this.loading_failed = false
      return this.$userCache.initialize()
        .then(() => api_devices.get_list())
        .then((response) => {
          this.loading = false
          if (response.status == 200) {
            this.loading_failed = false
            this.options = response.data.map(device => {
              const device_tag = this.$userCache.$tags.get_tag(device.id)
              return {
                key: device.id,
                label: device_tag?device_tag:device.id.substring(0,8)
              }
            })
            return Promise.resolve()
          } else {
            return Promise.reject({response})
          }
        })
        .catch(error => {
          this.loading = false
          this.loading_failed = true
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            // alert user
            alert('Could not load devices list.')
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    handleSelectDevice({key}) { // Note: vue-multiselect sends the whole option object on selection, hence the "{key}" as parameter here
      if (this.multiple) {
        if (key === ALL_DEVICES_KEY) {
          // 'all' was selected, ensure everything else is removed
          this.$emit('update:modelValue', [key])
        } else {
          // some option other than 'all' was selected
          // remove the 'all' option if already selected
          let value = this.value_as_array.filter(x => x !== ALL_DEVICES_KEY)
          // then, append the newly selected option if not already selected
          if (value.indexOf(key) < 0) {
            value.push(key)
          }
          this.$emit('update:modelValue', value)
        }
      } else {
        this.$emit('update:modelValue', key)
      }
    },
    handleRemoveDevice({key}) { // Note: vue-multiselect sends the whole option object on removal, hence the "{key}" as parameter here
      this.$emit(
        'update:modelValue',
        this.multiple
          ?this.value_as_array.filter(x => x !== key)
          :null
      )
    }
  },
  mounted: function() {
    this.loadData()
  }
}
</script>
