<template>
  <div>

    <loading-indicator v-if="loading" text="Loading modules.." />

    <div v-else class="container is-fluid" :style="{'padding-top': '40px'}">

      <div class="header level">
        <div class="level-left">
          <h3 class="is-size-4">All modules</h3>
        </div>
        <div class="level-right">
          <div class="level-item">
            <router-link
              class="add-new-button button is-pozetron-primary"
              to="/modules/new"
            >
              <span>Add new</span>
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
            </router-link>
          </div>
        </div>
      </div>

      <nav v-if="leaf_scripts.length > 0" class="panel">
        <modules-list-item
          v-for="script in leaf_scripts"
          :key="script.id"
          :data="script"
          @deleted="scripts = scripts.filter(x => script.id !== x.id)"
        />
      </nav>
      <div v-else>
        <section class="section has-text-centered">
          You did not add any modules, yet.
        </section>
      </div>

    </div>


  </div>
</template>

<script>
import api_scripts from './../../../api/scripts.js'
import LoadingIndicator from './../../common/LoadingIndicator.vue'
import { get_sso_url } from './../../../utils/sso.js'

import ModulesListItem from './ModulesListItem'

export default {
  name: 'modules-list-screen',
  components: {
    LoadingIndicator,
    ModulesListItem
  },
  data() {
    return {
      loading: true,
      scripts: []
    }
  },
  computed: {
    leaf_scripts: function() {
      return this.$userCache.$versions.get_leaves(this.scripts.slice().reverse()).slice().reverse()
    }
  },
  methods: {
    loadData() {
      this.loading = true
      return this.$userCache.initialize()
        .then(() => api_scripts.get_list({}))
        .then((response) => {
          this.loading = false
          if (response.status == 200) {
            this.scripts = response.data.slice().reverse()
            return Promise.resolve(response)
          } else {
            return Promise.reject({response})
          }
        })
        .catch((error) => {
          this.loading = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    }
  },
  mounted: function() {
    this.loadData()
  },
}
</script>
