<template>
  <div>

    <div :style="{marginBottom: '2rem'}">

      <div v-if="!claimed_device">

        <div v-if="claim_not_found" class="message is-danger">
          <div class="message-body">
            Sorry! we were not able to verify your claim code.
            <br />
            Please, try again with another code.
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label has-text-left">Code</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  type="text"
                  placeholder="Insert claim code"
                  :class="['input', {'is-danger': v$.value.code.$error}]"
                  :value="value.code"
                  @input="value = {...value, code: $event.target.value}; v$.value.code.$touch()"
                >
                <div v-if="v$.value.code.$error">
                  <p class="help is-danger" v-if="!v$.value.code.required">Field is required.</p>
                  <p class="help is-danger" v-if="!v$.value.code.maxLength">Must not contain more than {{ v$.value.code.$params.maxLength.max }} characters.</p>
                  <p class="help is-danger" v-if="!v$.value.code.allowedRegex">Only letters, numbers, "-" and "_" are allowed.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <h3 class="is-size-4">Congratulations</h3>
        <h5 class="is-size-5" :style="{marginBottom: '0.5rem'}">You have successfully claimed a new device!</h5>
        <p>
          Please, make sure your new device's <span class="tag is-light">ID</span> and <span class="tag is-light">keyId</span> are kept handy.
        </p>
      </div>

    </div>

    <div v-if="!claimed_device" class="level">
      <!-- Left side -->
      <div class="level-left">
        <div v-if="!claimed_device" class="level-item">
          <button
            class="back-button button is-light"
            :disabled="submitting"
            @click.prevent="handleCancel"
          >Cancel</button>
        </div>
      </div>

      <!-- Right side -->
      <div class="level-right">
        <div v-if="!claimed_device" class="level-item">
          <button
            :class="['forward-button button is-pozetron-primary has-tooltip-left', {'is-loading': submitting}]"
            :disabled="v$['value'].$invalid"
            :data-tooltip="v$['value'].$invalid?'Please ensure all fields are valid':null"
            @click.prevent="handleSubmit"
          >
            Claim
          </button>
        </div>
      </div>
    </div>

    <div v-else>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label has-text-left">ID</label>
          </div>
          <div class="field-body">
            <div class="field has-addons">
              <p class="control is-expanded">
                <input class="input" type="text" :value="claimed_device.id" @focus="$event.target.select()">
              </p>
              <p class="control">
                <a
                  ref="IDCopyButton"
                  v-clipboard:copy="claimed_device.id"
                  v-clipboard:success="(e) => {this.$refs.IDCopyButton.setAttribute('data-tooltip', 'Copied!')}"
                  v-clipboard:error="() => alert('Could not copy text.')"
                  class="button has-tooltip-left has-text-pozetron-primary"
                  data-tooltip="Copy"
                  @mouseleave="$refs.IDCopyButton.setAttribute('data-tooltip', 'Copy')"
                >
                  <font-awesome-icon :icon="['fas', 'copy']" />
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label has-text-left">keyId</label>
          </div>
          <div class="field-body">
            <div class="field has-addons">
              <p class="control is-expanded">
                <input class="input" type="text" :value="claimed_device.keyId" @focus="$event.target.select()">
              </p>
              <p class="control">
                <a
                  ref="keyIdCopyButton"
                  v-clipboard:copy="claimed_device.keyId"
                  v-clipboard:success="(e) => {this.$refs.keyIdCopyButton.setAttribute('data-tooltip', 'Copied!')}"
                  v-clipboard:error="() => alert('Could not copy text.')"
                  class="button has-tooltip-left has-text-pozetron-primary"
                  data-tooltip="Copy"
                  @mouseleave="$refs.keyIdCopyButton.setAttribute('data-tooltip', 'Copy')"
                >
                  <font-awesome-icon :icon="['fas', 'copy']" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>



  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'

import api_devices from './../../api/devices'
import { get_sso_url } from './../../utils/sso'


export default {
  name: 'claim-device-form',
  setup () {
    return { v$: useVuelidate() }
  },
  emits: ['close', 'claimed'],
  data () {
    return {
      value: {
        code: ''
      },
      submitting: false,
      claim_not_found: false,
      claimed_device: null
    }
  },
  validations: {
    value: {
      code: {
        required,
        maxLength: maxLength(255),
        allowedRegex: helpers.regex(/^([a-zA-Z0-9]|-|_)*$/) // i.e. alpha-numerics, underscore and hyphen
      }
    }
  },
  methods: {
    handleCancel() {
      this.$emit('close');
    },
    handleSubmit() {
      this.submitting = true
      this.claim_not_found = false

      return api_devices.claim(this.value.code)
        .then(response => {
          if (response.status == 200) {
            this.submitting = false
            this.claimed_device = response.data
            this.$emit('claimed', response.data);
            return Promise.resolve()
          } else {
            return Promise.reject(response)
          }
        })
        .catch(error => {
          this.submitting = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else if (error.response && error.response.status == 404) {
            this.claim_not_found = true
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    }
  }
}
</script>
