<template>
  <div>
    <div class="container is-fluid" :style="{'padding-top': '40px', 'padding-bottom': '40px'}"
      @dragover.prevent="isDnDModalActive = true"
      @dragenter.prevent="isDnDModalActive = true"
    >

      <h3 class="is-size-4" :style="{marginBottom: '2rem'}">New module</h3>

      <div
        :style="{marginBottom: '2rem'}"
      >

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label has-text-left">Module name</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  v-model="v$.main_form.module.name.$model"
                  type="text"
                  placeholder="Module name"
                  :class="['input', {'is-danger': v$.main_form.module.name.$error}]"
                >
                <div v-if="v$.main_form.module.name.$error">
                  <p class="help is-danger" v-if="!v$.main_form.module.name.required">Field is required.</p>
                  <p class="help is-danger" v-if="!v$.main_form.module.name.maxLength">Must not contain more than {{ v$.main_form.module.name.$params.maxLength.max }} characters.</p>
                  <p class="help is-danger" v-if="!v$.main_form.module.name.allowedRegex">Only letters, numbers, "-" and "_" are allowed.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label has-text-left">Content</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <div class="level" :style="{marginBottom: '0.5rem'}">
                  <!-- Left side -->
                  <div class="level-left">
                    <div class="level-item">
                      <button class="button is-small is-light" @click.prevent="$refs.file.click()">
                        <span class="icon is-small">
                          <i class="fas fa-file-code"></i>
                        </span>
                        <span>Load from file</span>
                      </button>
                    </div>
                    <div class="level-item">
                      <button
                        :class="['button', 'is-small', 'is-light']"
                        :disabled="url_form.loading"
                         @click.prevent="isURLModalActive = true"
                      >
                        <span v-if="url_form.loading" class="icon is-small">
                          <i class="fas fa-spinner fa-pulse"></i>
                        </span>
                        <span v-else class="icon is-small">
                          <i class="fas fa-link"></i>
                        </span>
                        <span>Load from URL</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div v-if="url_form.loading_error" class="message is-small is-danger">
                  <div class="message-body">
                    {{url_form.loading_error}}
                  </div>
                </div>

                <input type="file" ref="file" :style="{display: 'none'}" @change="onFileSelect" />
                <ace-editor
                  editor-id="main-editor"
                  v-model="v$.main_form.module.content.$model"
                  lang="python"
                />
                <div v-if="v$.main_form.module.content.$error">
                  <p class="help is-danger" v-if="!v$.main_form.module.content.required">Field is required.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label has-text-left">Deploy to</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <devices-field
                  placeholder="Select devices"
                  :multiple="true"
                  :show_all_option="false"
                  :disabled="Boolean(deploy_to)"
                  v-model="v$.main_form.devices.$model"
                />
                <p class="help" v-if="!deploy_to">Leave this empty, if you prefer deploying the module later.</p>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <button
              :class="['forward-button button is-pozetron-primary has-tooltip-right', {'is-loading': main_form.submitting}]"
              :disabled="v$['main_form'].$invalid"
              :data-tooltip="v$['main_form'].$invalid?'Please ensure all fields are valid':null"
              @click.prevent="handleSubmit"
            >
              {{v$.main_form.devices.$model.length?'Create and deploy': 'Create'}}
            </button>
          </div>
        </div>

        <!-- Right side -->
        <div class="level-right">

        </div>
      </div>


    </div>
    <b-modal
      v-model="isDnDModalActive"
      :can-cancel="['escape', 'outside']"
      full-screen
    >
      <div
        @dragover.prevent
        @dragenter.prevent
        @drop.prevent="onDrop"
        :style="{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff'
        }"
      >
        <span class="icon is-large">
          <font-awesome-icon size="2x" :icon="['fas', 'file-code']" />
        </span>
        <span>Drop a file here to prefill your module.</span>
      </div>
    </b-modal>

    <b-modal
      v-model="isURLModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="Load from URL"
      aria-modal
    >

      <form class="modal-card">
          <header class="modal-card-head">
              <p class="modal-card-title">Load from URL</p>
          </header>
          <section class="modal-card-body">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label has-text-left">URL</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      type="text"
                      placeholder="Insert URL"
                      :class="['input', {'is-danger': v$.url_form.url.$error}]"
                      v-model="v$.url_form.url.$model"
                    >
                    <div v-if="v$.url_form.url.$error">
                      <p class="help is-danger" v-if="!v$.url_form.url.required">Field is required.</p>
                      <p class="help is-danger" v-if="!v$.url_form.url.url">Must be a valid URL.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button
              :class="['forward-button button is-pozetron-primary has-tooltip-right', {'is-loading': url_form.loading}]"
              :disabled="v$['url_form'].$invalid"
              :data-tooltip="v$['url_form'].$invalid?'Please ensure all fields are valid':null"
              @click.prevent="loadURL"
            >
              Submit
            </button>
          </footer>
      </form>

    </b-modal>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, helpers, url } from '@vuelidate/validators'
import api_devices from '../../../api/devices'
import api_scripts from '../../../api/scripts'
import api_utils from '../../../api/utils'

import { get_sso_url } from './../../../utils/sso'
import { milliseconds_to_human_readable } from './../../../utils/date'

import AceEditor from './../../common/AceEditor.vue'
import DevicesField from './../../common/DevicesField.vue'


export default {
  name: 'modules-form-form',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    AceEditor,
    DevicesField
  },
  props: {
    deploy_to: {
      type: String
    },
    url: {
      type: String
    },
    name: {
      type: String
    }
  },
  data () {
    return {
      main_form: {
        module: {
          name: this.name || '',  // init from eventual prop
          content: '',
        },
        devices: [
          ...this.deploy_to?[this.deploy_to]:[]  // init from eventual prop
        ],

        submitting: false
      },
      url_form: {
        url: this.url || '',  // init from eventual prop

        loading: false,
        loading_error: null,
      },
      isDnDModalActive: false,
      isURLModalActive: false,
    }
  },
  watch: {
    'v$.main_form.module.content.$model': function() {
      this.url_form.loading_error = null
    },
    'v$.url_form.url.$model': function() {
      this.url_form.loading_error = null
    },
  },
  validations: {
    main_form: {
      module: {
        name: {
          required,
          maxLength: maxLength(255),
          allowedRegex: helpers.regex(/^([a-zA-Z0-9]|.|-|_)*$/) // i.e. alpha-numerics, dots, underscore and hyphen
        },
        content: {
          required
        }
      },
      devices: {

      }
    },
    url_form: {
      url: {
        required,
        url
      }
    }
  },
  methods: {
    onDrop(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isDnDModalActive = false
      if (event.dataTransfer.files.length === 1) {
        this.loadFile(event.dataTransfer.files[0])
      }
    },
    onFileSelect(event) {
      event.stopPropagation()
      event.preventDefault()
      this.isDnDModalActive = false
      this.loadFile(event.target.files[0])
    },
    async loadFile(file) {
      this.v$.main_form.module.name.$model = file.name
      this.v$.main_form.module.content.$model = await file.text()
    },
    loadURL() {
      this.isURLModalActive = false
      this.url_form.loading = true
      this.url_form.loading_error = null

      return api_utils.remote_fetch(this.url_form.url)
        .then(response => {
          if (response.status === 200) {
            this.v$.main_form.module.content.$model = response.data.content
            this.url_form.loading = false
            return Promise.resolve(response)
          } else {
            return Promise.reject({response})
          }
        })
        .catch(error => {
          this.url_form.loading = false
          if (error.response && error.response.status === 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else if (error.response && error.response.status === 400) {
            this.url_form.loading_error = error.response.data?.url || error.response.data?.[0] || 'Something went wrong when trying to fetch URL content'
            // re-raise
            return Promise.reject(error)
          } else if (error.response && error.response.status === 429) {
            const availability = error.response.headers['retry-after'] !== undefined
              ?`in ${milliseconds_to_human_readable(Number(error.response.headers['retry-after']) * 1000)}`
              :'later'
            this.url_form.loading_error = `Your request to fetch URL content was throttled. Please, try again ${availability}, or you can copy/paste code directly into the code editor.`
            // re-raise
            return Promise.reject(error)
          } else {
            this.url_form.loading_error = 'Something went wrong when trying to fetch URL content'
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    handleSubmit() {
      this.main_form.submitting = true

      return api_scripts.create(this.v$.main_form.module.$model)
        .then(response => {
          if (response.status == 200) {
            if (this.v$.main_form.devices.$model.length) {
              return this.handleDeploy(response.data.id).then(() => {
                this.main_form.submitting = false
                return Promise.resolve(response)
              })
            } else {
              return Promise.resolve(response)
            }
          } else {
            return Promise.reject(response)
          }
        })
        .then((response) => {
          this.main_form.submitting = false
          if (this.deploy_to) {
            this.$router.push({path: `/devices/${this.deploy_to}/modules`})
          } else {
            this.$router.push({name: 'modules_single', params: {script_id: response.data.id}})
          }
          return Promise.resolve()
        })
        .catch(error => {
          this.main_form.submitting = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    handleDeploy(script_id) {
      return Promise.all(
        this.v$.main_form.devices.$model.map((device_id) => (
          api_devices.deploy_module(device_id, script_id)
        ))
      )

    }
  },
  mounted() {
    if (this.url) {
      this.loadURL()
    }
  }
}
</script>

<style>
.modal.is-full-screen > .animation-content {
  background-color: transparent !important;
}
</style>
