import XXH from 'xxhashjs'
import config from './../../app.config.json'

export const hash_str = (str) => {
  return XXH.h32(str, config.HASH_TABLE_SEED).toString(16)
}

export const update_hash_table = (str_list) => {
  if (str_list && str_list.length) {
    localStorage.setItem(
      config.HASH_TABLE_STORAGE_KEY,
      JSON.stringify(
        str_list.reduce((acc, curr) => {
          let data = {...acc}
          data[hash_str(curr)] = curr
          return data
        }, {})
      )
    )
  } else {
    localStorage.removeItem(config.HASH_TABLE_STORAGE_KEY)
  }
}

export const parse_hash = (hash) => {
  const hash_table = localStorage.getItem(config.HASH_TABLE_STORAGE_KEY)
  if (hash_table) {
    return JSON.parse(hash_table)[hash]
  }
  return null
}
