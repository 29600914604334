import api_base from './base.js'

const api_devices = {
  get_list: (params = {}) => (
    api_base(true).get('/user/v1/devices/', {params})
  ),
  get_single: (id) => (
    api_base(true).get(`/user/v1/devices/${id}/`)
  ),
  get_logs: (id, params = {}) => (
    api_base(true).get(`/user/v1/devices/${id}/logs/`, {params})
  ),
  reboot: (id) => (
    api_base(true).post(`/user/v1/devices/${id}/reboot/`)
  ),
  forget_network: (id) => (
    api_base(true).post(`/user/v1/devices/${id}/forget-network/`)
  ),
  log_mode_memory: (id) => (
    api_base(true).post(`/user/v1/devices/${id}/log-mode/?enable`, {enable: true, mode: 'memory'})
  ),
  log_mode_file: (id) => (
    api_base(true).post(`/user/v1/devices/${id}/log-mode/?enable`, {enable: true, mode: 'file', file_size: 1024})
  ),
  log_mode_disabled: (id) => (
    api_base(true).post(`/user/v1/devices/${id}/log-mode/?disable`)
  ),
  claim: (code) => (
    api_base(true).put(`/user/v1/devices/${code}/claim/`)
  ),
  get_deployed_modules: (id) => (
    api_base(true).get(`/user/v1/devices/${id}/scripts/`)
  ),
  delete_deployed_module: (device_id, module_id) => (
    api_base(true).delete(`/user/v1/devices/${device_id}/scripts/${module_id}/`)
  ),
  deploy_module: (device_id, module_id) => (
    api_base(true).post(`/user/v1/devices/${device_id}/scripts/`, {id: module_id})
  ),
  get_firmware: (device_id, params) => (
    api_base(true).get(`/user/v1/devices/${device_id}/firmware/`, {params})
  )
}

export default api_devices
