<template>
  <div>

    <loading-indicator v-if="loading" text="Loading device.." />

    <div v-else class="container is-fluid" :style="{'padding-top': '40px'}">

      <div class="header level">
        <div class="level-left">
          <router-link to="/" class="level-item">
            <span class="icon is-small">
              <i class="fas fa-arrow-left"></i>
            </span>
          </router-link>
          <div class="level-item">
            <div>
              <div v-if="tag">{{tag}}</div>
              <div v-else>{{ device.id?device.id.substring(0,8):'' }}</div>
              <div class="is-size-7">Last seen: {{ device.last_seen?new Date(from_api_timestamp(device.last_seen)).toLocaleString():'never' }}</div>
            </div>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <div class="buttons device-actions">
              <a class="button is-small is-light" @click.prevent="reboot()">Reboot</a>
              <a class="button is-small is-light" @click.prevent="forget_network()">Forget Network</a>
              <a class="button is-small is-light" @click.prevent="log_mode_memory()">Log Mode Memory</a>
              <a class="button is-small is-light" @click.prevent="log_mode_file()">Log Mode File</a>
              <a class="button is-small is-light" @click.prevent="log_mode_disabled()">Log Mode Disabled</a>
            </div>
          </div>
        </div>
      </div>

      <section>

        <div class="tabs is-boxed is-medium">
          <ul>
            <li :class="[{'is-active': $route.name === 'devices_single'}]">
              <router-link :to="`/devices/${id_hash}`">
                <span class="icon is-small"><font-awesome-icon :icon="['fas', 'history']" /></span>
                <span>Logs</span>
              </router-link>
            </li>
            <li :class="[{'is-active': $route.name === 'devices_single_modules'}]">
              <router-link :to="`/devices/${id_hash}/modules`">
                <span class="icon is-small"><font-awesome-icon :icon="['fab', 'python']" /></span>
                <span>Modules</span>
              </router-link>
            </li>
            <li :class="[{'is-active': $route.name === 'devices_single_firmware'}]">
              <router-link :to="`/devices/${id_hash}/firmware`">
                <span class="icon is-small"><font-awesome-icon :icon="['fas', 'microchip']" /></span>
                <span>Firmware</span>
              </router-link>
            </li>
            <li :class="[{'is-active': $route.name === 'devices_single_repl'}]">
              <router-link :to="`/devices/${id_hash}/repl`">
                <span class="icon is-small"><font-awesome-icon :icon="['fas', 'microchip']" /></span>
                <span>Console</span>
              </router-link>
            </li>
          </ul>
        </div>

        <router-view
          :device_id="device_id"
        />
      </section>
    </div>

  </div>
</template>

<script>
import api_devices from './../../api/devices.js'
import LoadingIndicator from './../common/LoadingIndicator.vue'
import { parse_hash, update_hash_table } from './../../utils/hash_table.js'
import { from_api_timestamp } from './../../utils/date.js'
import { get_sso_url } from './../../utils/sso.js'

export default {
  name: 'devices-single-screen',
  components: {
    'loading-indicator': LoadingIndicator
  },
  data() {
    return {
      loading: true,
      device_id: null,
      device: {},
    }
  },
  props: {
    id_hash: {
      type: String,
      required: true
    }
  },
  computed: {
    tag: function () {
      return this.$userCache.$tags.get_tag(this.device_id)
    }
  },
  methods: {
    updateHashTable() {
      return api_devices.get_list()
        .then((response) => {
          if (response.status == 200) {
            update_hash_table(response.data.map(device => device.id))
            return Promise.resolve(response)
          }
          return Promise.reject({response})
        })
    },
    loadData() {
      this.loading = true

      return Promise.resolve()
        .then(() => {
          // Parse url id hash into an actual device id
          this.device_id = parse_hash(this.id_hash)

          // Could not find a matching device_id?
          if (!this.device_id) {
            // Re-construct the hash table
            return this.updateHashTable()
              .then(() => {
                // Then, try parsing the id hash again
                this.device_id = parse_hash(this.id_hash)
                return Promise.resolve()
              })
          }

          return Promise.resolve()
        })
        .then(() => {
          // If we've got no id (there's no hash table or url hash is invalid), redirect to the devices list screen
          if (!this.device_id) {
            this.$router.push({name: 'devices_list'})
            return Promise.reject({error: 'invalid_id_hash'})
          }
        })
        .then(() => this.$userCache.initialize())
        .then(() => api_devices.get_single(this.device_id))
        .then((response) => {
          this.loading = false
          if (response.status == 200) {
            this.device = response.data
            return Promise.resolve(response)
          }
          return Promise.reject({response})
        })
        .catch((error) => {
          this.loading = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else if (error.error === 'invalid_id_hash') {
            // re-raise
            return Promise.reject(error)
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    reboot() {
      return api_devices.reboot(this.device_id)
    },
    forget_network() {
      return api_devices.forget_network(this.device_id)
    },
    log_mode_memory() {
      return api_devices.log_mode_memory(this.device_id)
    },
    log_mode_file() {
      return api_devices.log_mode_file(this.device_id)
    },
    log_mode_disabled() {
      return api_devices.log_mode_disabled(this.device_id)
    },
    from_api_timestamp
  },
  mounted: function() {
    this.loadData()
  }
}
</script>

<style>
.navbar-burger {
  color: #fff;

  /* Fix burger button height to expand as navbar height expands for any reason */
  height: auto;
}
.navbar-burger:hover {
  color: #fff;
}
</style>
