<template>
  <div>

    <div>
      Viewing logs for latest
      <div class="field select-latest">
        <div class="control has-icons-left">
          <div :class="['select is-small', {'is-loading': loading}]">
            <select class="from" v-model="from" :disabled="loading">
              <option :value="60*60">1 hour</option>
              <option :value="60*60*3">3 hours</option>
              <option :value="60*60*24">1 day</option>
              <option :value="60*60*24*3">3 days</option>
              <option :value="60*60*24*7">Week</option>
              <option :value="60*60*24*30">Month</option>
              <option :value="null">All time</option>
            </select>
          </div>
          <div class="icon is-small is-left">
            <i class="fas fa-clock"></i>
          </div>
        </div>
      </div>
    </div>
    <div id="logs" v-if="logs.length || logs_in_viewer.length">
      <div v-for="(log, index) in logs_in_viewer" :key='index'>{{`${log.timestamp}: ${log.text}`}}</div>
      <infinite-loading @infinite="infiniteHandler">
        <template v-slot:no-results>No logs found.</template>
        <template v-slot:no-more><span></span></template>
      </infinite-loading>
    </div>
    <div v-else>No logs found.</div>

  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

import api_devices from './../../api/devices.js'
import { to_api_timestamp, from_api_timestamp } from './../../utils/date.js'
import { get_sso_url } from './../../utils/sso.js'

export default {
  name: 'devices-single-screen-logs',
  components: {
    'infinite-loading': InfiniteLoading
  },
  data() {
    return {
      loading: true,
      logs: [],
      logs_in_viewer: [],
      from: 60*60
    }
  },
  props: {
    device_id: {
      type: String,
      required: true
    }
  },
  watch: {
    from: function (val) {
      this.loading = true
      this.loadData({from: val?to_api_timestamp(Date.now() - (val * 1000)):null})
    }
  },
  methods: {
    infiniteHandler($state) {
      // try showing more logs
      const loaded = this.showMoreLogs()

      if (loaded) {
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    showMoreLogs: function() {
      const LOAD_MORE_COUNT = 100;

      // if we have logs in memory
      if (this.logs.length) {
        // remove a number of logs from memory, and show them in log viewer.
        this.logs_in_viewer = this.logs_in_viewer.concat(this.logs.splice(0, this.logs.length < LOAD_MORE_COUNT ? this.logs.length : LOAD_MORE_COUNT));
        return true // tell caller we have indeed loaded some logs
      } else {
        return false // tell caller there were no logs to show
      }
    },
    loadData(params = null) {
      // reset logs
      this.logs = []
      this.logs_in_viewer = []

      // fetch logs
      return api_devices.get_logs(this.device_id, params)
        .then((response) => {
          this.loading = false
          if (response.status == 200) {
            this.logs = response.data
            // reverse logs list (the api response is assumed to be in chrnological order)
            this.logs.reverse()
            this.showMoreLogs()
            return Promise.resolve(response)
          }
          return Promise.reject({response})
        })
        .catch((error) => {
          this.loading = false
          if (error.response && error.response.status == 401) {
            // Redirect to single sign-on url
            window.location.assign(get_sso_url())
          } else {
            alert('Something went wrong.')
            // re-raise
            return Promise.reject(error)
          }
        })
    },
    to_api_timestamp,
    from_api_timestamp
  },
  mounted: function() {
    this.loadData({from: this.from?from_api_timestamp(Math.floor((Date.now() - this.from) / 1000000)):null})
  }
}
</script>

<style>
.select-latest.field,
.select-latest.field .control {
  display: inline-block;
}
</style>
