import PozetronWebSharedLayout from './components/layout/pozetron-web-shared-layout.vue'

import config from './../app.config.json'

import DevicesListScreen from './components/screens/DevicesListScreen.vue'
import DevicesSingleScreen from './components/screens/DevicesSingleScreen.vue'
import DevicesSingleScreenLogs from './components/screens/DevicesSingleScreenLogs.vue'
import DevicesSingleScreenModules from './components/screens/DevicesSingleScreenModules.vue'
import DevicesSingleScreenFirmware from './components/screens/DevicesSingleScreenFirmware.vue'
import DevicesSingleScreenREPL from './components/screens/DevicesSingleScreenREPL.vue'

import ClaimDeviceScreen from './components/screens/ClaimDeviceScreen.vue'

import ModulesListScreen from './components/screens/modules/ModulesListScreen.vue'
import ModulesSingleScreen from './components/screens/modules/ModulesSingleScreen.vue'
import ModulesFormScreen from './components/screens/modules/ModulesFormScreen.vue'

const routes = [
  {
    path: '/',
    component: PozetronWebSharedLayout,
    props: {
      devices_app_base_url: '',
      actions_app_base_url: config.ACTIONS_APP_BASE_URL,
      dashboards_base_url: config.DASHBOARDS_BASE_URL,
      shop_base_url: config.SHOP_BASE_URL,
      tutorials_base_url: config.TUTORIALS_BASE_URL,
      billing_app_base_url: config.BILLING_APP_BASE_URL,
    },
    redirect: 'devices',
    children: [
      {
        path: 'devices',
        children: [
          {
            path: '',
            component: DevicesListScreen,
            name: 'devices_list'
          },
          {
            path: ':id_hash',
            component: DevicesSingleScreen,
            props: true,
            children: [
              { path: '',
                component: DevicesSingleScreenLogs,
                name: 'devices_single'
              },
              {
                path: 'modules',
                component: DevicesSingleScreenModules,
                name: 'devices_single_modules'
              },
              {
                path: 'firmware',
                component: DevicesSingleScreenFirmware,
                name: 'devices_single_firmware'
              },
              {
                path: 'repl',
                component: DevicesSingleScreenREPL,
                name: 'devices_single_repl'
              },
            ]
          },
        ]
      },
      {
        path: 'modules',
        children: [
          {
            path: '',
            component: ModulesListScreen,
            name: 'modules_list'
          },
          {
            path: 'new',
            component: ModulesFormScreen,
            props: route => ({
              deploy_to: route.query.deploy_to,
              url: route.query.url,
              name: route.query.name
            }),
            name: 'modules_new'
          },
          {
            path: ':script_id',
            component: ModulesSingleScreen,
            props: true,
            name: 'modules_single'
          }
        ]
      },
      {
        path: '/claim',
        component: ClaimDeviceScreen,
        name: 'claim_device'
      },
    ]
  },
]

export default routes
