const EPOCH_DELTA = 946684800 // seconds from 1970 until 2000

/**
   * Converts js timestamps (milliseconds from 1970) into micropython timestamps (seconds from 2000).
   * @param  {int} timestamp  Milliseconds
   */
export const to_micropython_timestamp = (timestamp) => {
  return Math.floor(timestamp / 1000) - EPOCH_DELTA
}

/**
 * Converts micropython timestamps (seconds from 2000) into js timestamps (milliseconds seconds from 1970).
 * @param  {int} timestamp  Seconds
 */
export const from_micropython_timestamp = (timestamp) => {
  return (timestamp + EPOCH_DELTA) * 1000
}


/**
 * Converts js timestamps (milliseconds seconds from 1970) into api timestamps (seconds from 1970).
 * @param  {int} timestamp  Seconds
 */
export const to_api_timestamp = (timestamp) => {
  return Math.floor(timestamp / 1000)
}


/**
 * Converts api timestamps (seconds from 1970) into js timestamps (milliseconds seconds from 1970).
 * @param  {int} timestamp  Seconds
 */
export const from_api_timestamp = (timestamp) => {
  return (timestamp) * 1000
}


/**
 * Converts milliseconds into a human readable form.
 * https://stackoverflow.com/a/8212878
 * @param  {int} milliseconds  Milliseconds
 */
 export const milliseconds_to_human_readable = (milliseconds) => {
  // TIP: to find current time in milliseconds, use:
  // var  current_time_milliseconds = new Date().getTime();

  function numberEnding (number) {
      return (number > 1) ? 's' : '';
  }

  var temp = Math.floor(milliseconds / 1000);
  var years = Math.floor(temp / 31536000);
  if (years) {
      return years + ' year' + numberEnding(years);
  }
  //TODO: Months! Maybe weeks?
  var days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
      return days + ' day' + numberEnding(days);
  }
  var hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
      return hours + ' hour' + numberEnding(hours);
  }
  var minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
      return minutes + ' minute' + numberEnding(minutes);
  }
  var seconds = temp % 60;
  if (seconds) {
      return seconds + ' second' + numberEnding(seconds);
  }
  return 'less than a second'; //'just now' //or other string you like;
}
