<template>
  <component
    :is="editing?'div':'router-link'"
    :to="`/devices/${hash_str(data.id)}`"
    class="panel-block"
  >
    <div :style="{'flex': 1, 'display': 'flex', 'alignItems': 'center'}">
      <button
        v-if="editing"
        class="button is-small is-white"
        :style="{marginRight: '0.75em'}"
        @click.prevent="closeTagForm()"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'times']" />
        </span>
      </button>
      <button
        v-else
        class="button is-small is-white"
        :style="{marginRight: '0.75em'}"
        @click.prevent="openTagForm()"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fas', $userCache.$tags.get_tag(data.id)?'pen':'plus']" />
        </span>
      </button>

      <form
        v-if="editing"
        @submit.prevent="handleTagSubmit()"
      >
        <div class="field has-addons">
          <div class="control">
            <input
              v-model="tag_form.tag"
              :class="['input', {'is-danger': tag_form.error}]"
              type="text"
              placeholder="Insert a tag or leave empty to reset"
            >
          </div>
          <div class="control">
            <button
              class="button"
            >
              <span class="icon is-small has-text-success">
                <font-awesome-icon :icon="['fas', 'check']" />
              </span>
            </button>
          </div>
        </div>
        <p v-if="tag_form.error" class="help is-danger">{{tag_form.error}}</p>
      </form>
      <span v-else>
        {{get_device_label(data.id)}}
      </span>
    </div>

    <div
      v-if="context_script_id"
      :class="['options-button', 'dropdown', 'is-right', {'is-active': isDropdownActive}]"
      v-on-clickaway="away"
    >
      <div class="dropdown-trigger">
        <button
          class="button is-white is-small"
          @click.prevent="isDropdownActive=!isDropdownActive"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
          </span>
        </button>
      </div>
      <div class="dropdown-menu">
        <div class="dropdown-content">
          <template v-if="context_script_id">
            <a
              href="#"
              :class="['dropdown-item undeploy-button', {'is-disabled': undeploying}]"
              @click.prevent="undeployFromDevice()"
            >
              <span class="icon is-small">
                <font-awesome-icon :icon="['fas', 'trash-alt']" />
              </span>
              Undeploy from this device
            </a>
          </template>
        </div>
      </div>
    </div>

  </component>

</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

import LoadingIndicator from './../common/LoadingIndicator.vue'

import api_devices from './../../api/devices.js'
import { get_sso_url } from './../../utils/sso.js'
import { hash_str } from './../../utils/hash_table.js'


export default {
  name: 'modules-list-item',
  mixins: [ clickaway ],
  components: {
    'loading-indicator': LoadingIndicator
  },
  props: {
    context_script_id: {
      type: String
    },
    data: {
      type: Object,
      required: true
    }
  },
  emits: ['deleted'],
  data() {
    return {
      isDropdownActive: false,
      editing: false,
      undeploying: false,
      undeploying_failed: false,
      tag_form: {
        tag: null,
        error: null
      }
    }
  },
  methods: {
    hash_str,
    away() {
      this.isDropdownActive = false;
    },
    get_device_label(id) {
      return this.$userCache.$tags.get_tag(id)?this.$userCache.$tags.get_tag(id):id.substring(0,8)
    },
    openTagForm() {
      this.editing = true
      this.tag_form = {
        tag: this.$userCache.$tags.get_tag(this.data.id),
        error: null
      }
    },
    closeTagForm() {
      this.editing = false
      this.tag_form = {
        tag: null,
        error: null
      }
    },
    handleTagSubmit() {
      // reset error
      this.tag_form.error = null

      // update tag in local cache
      this.$userCache.$tags.set_tag(this.data.id, this.tag_form.tag)

      // sync cache
      return this.$userCache.update({tags: true})
        .catch((error) => {
          if (error?.response?.status === 400) {
            this.tag_form.error = error.response?.data?.detail
          } else if (error?.response?.status === 502) {
            this.tag_form.error = 'Cache is corrupt. Please, reset it and try again.'
          } else if (error?.response?.status === 503) {
            this.tag_form.error = 'Could not update cache. Please, try again.'
          }
          return Promise.reject(error)
        })
        .then(() => this.$userCache.initialize())
        .then(() => {
          this.closeTagForm()
        })
        .catch(error => {
          if (error?.response?.status === 400) {
            // no need to re-raise already handled 400 validation errors
            return Promise.resolve()
          }
          // re-raise other errors so edge-cases can be catched
          return Promise.reject(error)
        })
    },
    undeployFromDevice() {
      return this.$confirm(
        'This will undeploy the current module from the selected device. Any other devices using the same module will not be affected.',
        null,
        'warning',
        {
          confirmButtonText: 'Okay, undeploy'
        }
      )
        .then(
          () => {
            this.undeploying = true
            this.undeploying_failed = false
            return api_devices.delete_deployed_module(this.data.id, this.context_script_id)
              .then(response => {
                if (response.status == 204) {
                  this.undeploying = false
                  this.$emit('deleted')
                } else {
                  return Promise.reject({response})
                }
              })
              .catch(error => {
                this.undeploying = false
                this.undeploying_failed = true
                if (error.response && error.response.status == 401) {
                  // Redirect to single sign-on url
                  window.location.assign(get_sso_url())
                } else {
                  alert('Something went wrong.')
                  // re-raise
                  return Promise.reject(error)
                }
              })
          },
          () => {
            // do nothing on cancel
            return Promise.resolve()
          }
        )
    },
  }
}
</script>

<style scoped>
.is-disabled{
  pointer-events: none;
  opacity: .65;
}
</style>
