import api_base from './base.js'


const api_replsessions = {
  create: ({device_id}) => (
    api_base(true).post('/user/v1/replsessions/', {
      device: device_id,
    })
  ),
  delete: (session_id) => (
    api_base(true).delete(`/user/v1/replsessions/${session_id}/`)
  ),
}

export default api_replsessions
